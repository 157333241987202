import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from './ui/button';
import { type PaidPaymentIntentResponse } from '@/types/Payment';
import { errorToast } from './ui/use-toast';
import { formatPrice } from '@/lib/utils';
import { useState } from 'react';

interface CheckoutFormProps {
    paymentIntent: PaidPaymentIntentResponse;
    total: number;
}

export default function CheckoutForm({ paymentIntent, total }: CheckoutFormProps): JSX.Element {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setIsLoading(true);
        if (!stripe || !elements) {
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError?.message) {
            errorToast(submitError.message);
            return;
        }
        const { error } = await stripe.confirmPayment({
            clientSecret: paymentIntent.clientSecret,
            elements,
            confirmParams: {
                return_url: `${location.origin}/order/complete`,
            },
            redirect: 'if_required',
        });
        if (error?.message) {
            errorToast(error.message);
        }
        setIsLoading(false);
    }

    return (
        <form className="space-y-4" onSubmit={handleSubmit}>
            <PaymentElement className="outline-none ring-0 focus-visible:outline-none" />
            <Button className="w-full" size="lg" type="submit" disabled={!stripe || !elements} isLoading={isLoading}>
                Pay {formatPrice(total)} SEK
            </Button>
        </form>
    );
}

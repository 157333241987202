import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { RoleType, type RoleResponse } from '@/types/Role';
import { DeleteDialog } from '@/components/ui/DeleteDialog';
import { useDeleteRoleMutation, useEditRoleMutation } from '@/services/rolesApi';
import { RoleDialog } from './RoleDialog';
import { Button } from '@/components/ui/button';

interface Props {
    role: RoleResponse;
}

export default function RoleCard({ role }: Props): JSX.Element {
    const [deleteRole, { isLoading }] = useDeleteRoleMutation();
    const [editRole, { isLoading: isEditing }] = useEditRoleMutation();
    const navigate = useNavigate();

    return (
        <Card
            className="flex flex-1 h-full flex-col hover:bg-accent hover:cursor-pointer relative"
            onClick={() => navigate(`/admin/roles/${role.id}`)}
        >
            <CardHeader>
                <CardTitle>{role.title}</CardTitle>
            </CardHeader>
            <CardContent className="pb-4 h-full">{role.description}</CardContent>
            <CardFooter className="justify-end gap-2">
                <DeleteDialog
                    deleteItem={() => deleteRole({ roleId: role.id }).unwrap()}
                    isLoading={isLoading}
                    disabled={role.roleType !== RoleType.CUSTOM}
                    disabledMessage={getToolTipFromRole(role)}
                >
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
                {/* This propagation needs to be here in order for a click on the sheet itself not to propagate */}
                <div onClick={(e) => e.stopPropagation()}>
                    <RoleDialog
                        role={role}
                        onSubmit={(data) => editRole({ ...data, id: role.id }).unwrap()}
                        isLoading={isEditing}
                    >
                        <Button>Edit</Button>
                    </RoleDialog>
                </div>
            </CardFooter>
        </Card>
    );
}

const getToolTipFromRole = (role: RoleResponse): string => {
    if (role.roleType === RoleType.ADMIN) {
        return 'You cannot delete the admin role';
    }
    return 'You cannot delete a representative role';
};

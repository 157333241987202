import { Button } from '../ui/button';
import { useForm } from 'react-hook-form';
import { Form } from '../ui/form';
import { FormInput, FormTextArea } from '../ui/FormHelpers';

import type { RoleFormInput } from '@/types/Role';
import { errorToastFromCatch, successToast } from '../ui/use-toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';

interface RoleDialogProps {
    onSubmit: (data: RoleFormInput) => Promise<unknown>;
    isLoading: boolean;
    role?: RoleFormInput;
    children: React.ReactNode;
}

export const RoleDialog = (props: RoleDialogProps): JSX.Element => {
    const { onSubmit, role, isLoading } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const form = useForm<RoleFormInput>({
        defaultValues: {
            title: role?.title,
            description: role?.description,
        },
    });
    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const control = form.control;

    const handleFormSubmit = (data: RoleFormInput) => {
        onSubmit(data)
            .then(() => {
                successToast('Role saved successfully');
                form.reset();
                setOpen(false);
                navigate('/admin/roles');
            })
            .catch(errorToastFromCatch);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <DialogTrigger asChild>{props.children}</DialogTrigger>
            </div>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Role Information</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="flex flex-col gap-2">
                            <FormInput
                                control={control}
                                id="title"
                                label="Title"
                                error={errors.title?.type === 'required' ? 'Title is required' : undefined}
                            />
                            <FormTextArea
                                control={control}
                                id="description"
                                label="Description"
                                error={errors.description?.type === 'required' ? 'Description is required' : undefined}
                            />
                            <div className="flex justify-end gap-2">
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" isLoading={isLoading}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

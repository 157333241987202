import { useState } from 'react';
import { useCreateInviteLinkMutation, useGetInviteLinkMutation } from '@/services/rolesApi';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogHeader } from '@/components/ui/dialog';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { DatePicker } from '../ui/date-picket';
import { Spinner } from '../ui/spinner';
import { Input } from '../ui/input';
import { CopyCheckIcon, CopyIcon } from 'lucide-react';

interface CreateInviteLinkProps {
    roleId: string;
    roleName: string;
}

export function CreateInviteLink({ roleId, roleName }: CreateInviteLinkProps): JSX.Element {
    const [experationTime, setExperationTime] = useState<string | undefined>();
    const [createInviteLink] = useCreateInviteLinkMutation();
    const [getInviteLink, { isLoading }] = useGetInviteLinkMutation();
    const [isGenerated, setIsGenerated] = useState(false);
    const [inviteId, setInviteId] = useState<string | undefined>();
    const [copied, setCopied] = useState(false);

    const getInviteLinkData = async () => {
        getInviteLink({ roleId })
            .unwrap()
            .then((response) => {
                setInviteId(response.inviteId);
                setIsGenerated(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleSubmit = async () => {
        createInviteLink({ roleId, experationTime: experationTime || '' })
            .unwrap()
            .then((response) => {
                setInviteId(response.inviteId);
                setIsGenerated(true);
                copyToClipboard(window.location.origin + '/invite/' + response.inviteId);
            })
            .catch(errorToastFromCatch);
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch((err) => console.error('Failed to copy link:', err));
        successToast(text + ' copied to clipboard');
    };

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button onClick={getInviteLinkData}>Create invite link</Button>
                </DialogTrigger>
                {isLoading ? (
                    <DialogContent>
                        <div className="flex justify-center items-center">
                            <Spinner size="lg" />
                        </div>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Generate invite link to role {roleName}</DialogTitle>
                        </DialogHeader>

                        {isGenerated ? (
                            <div className="flex flex-row items-center gap-2">
                                <Input value={window.location.origin + '/invite/' + inviteId} readOnly />
                                <Button
                                    onClick={() => copyToClipboard(window.location.origin + '/invite/' + inviteId)}
                                    size="icon"
                                >
                                    {copied ? <CopyCheckIcon className="h-5 w-5" /> : <CopyIcon className="h-5 w-5" />}
                                </Button>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-2">
                                <DatePicker
                                    date={experationTime}
                                    setDate={(date) => setExperationTime(date)}
                                    placeholder="Select expiration date"
                                />
                                <Button disabled={experationTime === undefined} onClick={handleSubmit}>
                                    Generate Link
                                </Button>
                            </div>
                        )}
                    </DialogContent>
                )}
            </Dialog>
        </div>
    );
}

import { UserIcon } from 'lucide-react';
import { useAuth } from '@/hooks/apiHooks';
import { useSignOutUserMutation } from '@/services/authApi';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useNavigate } from 'react-router-dom';
import { errorToastFromCatch, successToast } from './ui/use-toast';
import useScreenSize from '@/hooks/useScreenSize';

export default function UserMenu(): JSX.Element {
    const { isMobile } = useScreenSize();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [signOut] = useSignOutUserMutation();

    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="flex space-x-2 items-center min-w-fit">
                <div className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-2 py-1 h-10 space-x-1 hover:bg-gray-500/20">
                    <UserIcon className="h-5 w-5" /> {!isMobile && <p>{`${user?.firstName} ${user?.lastName}`}</p>}
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem className="cursor-pointer" onClick={() => navigate('/tickets')}>
                    Tickets
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    onClick={() => {
                        signOut()
                            .unwrap()
                            .then(() => successToast('You were logged out'))
                            .catch(errorToastFromCatch);
                    }}
                    className="cursor-pointer"
                >
                    Sign out
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
